<template>
	<div class="certificate_box LC_box">
		<div class="certificateHeader LC_Header">
			我的证书
			<div class="batchPrint" @click="batchPrint">批量打印</div>
			<span class="allOrAdopt" @click="allOrAdoptFun">{{allOrAdopt}}</span>
		</div>
		<div class="certificateList">
			<el-table
			    ref="multipleTable"
			    :data="tableData"
			    tooltip-effect="dark"
				border
			    style="width: 100%"
			    @selection-change="handleSelectionChange">
			    <el-table-column
			      type="selection"
				  :selectable='checkboxT'
			      width="55">
			    </el-table-column>
			    <el-table-column
			      label="课程名称"
				  width="250">
				  <template slot-scope="scope">
						<div class="courseName" :title="scope.row.cou_name">{{scope.row.cou_name}}</div>
				  </template>
			    </el-table-column>
			    <el-table-column
			      prop="sig_course_year"
			      label="年度"
				  width="97">
			    </el-table-column>
			    <el-table-column
			      prop="stex_score"
			      label="成绩"
				  width="102">
			    </el-table-column>
				<el-table-column
				  prop="stex_ismakeup_text"
				  label="考试类型"
				  width="132">
				</el-table-column>
				<el-table-column
				  label="是否通过"
				  width="124">
				  <template slot-scope="scope">
				  		<span v-if="scope.row.stex_ispass == 0">否</span>
						<span v-else>是</span>
				  </template>
				</el-table-column>
				<el-table-column
				  label="打印成绩">
				  <template slot-scope="scope">
						<div class="isPrint" v-if="scope.row.stex_ispass == 1" @click="singlePrint(scope.row)">打印</div>
						<div class="noPrint" v-else>打印</div>	
				  </template>
				</el-table-column>
			  </el-table>
		</div>
		<div class="certificatePaginat" v-if="total > pageSize">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="total"
			  :page-size="pageSize"
			  @current-change="changPage">
			</el-pagination>
		</div>
		<el-dialog
		  title="打印证书"
		  :visible.sync="printVisible"
		  fullscreen
		  class="printDialog">
			<div class="prin_info" v-if="cuurInfo.length">
				<div class='printingDiv' id="print">
					<div class='printingTop' :style="{backgroundImage:'url('+ templateImg+')'}"></div>
					<!--中间内容-->
					<div class="printing_content">
						<div class="printing_student" :style="{backgroundImage:'url('+ templateImg+')'}">
							 <span class="printing_line">{{cuurInfo[0].stu_name}}</span>同志，
							 <span>身份证号码</span>
							 <span v-if="cuurInfo[0].stu_cardid" class="printing_line">{{cuurInfo[0].stu_cardid}}</span>完成
							 <span class="printing_line">{{cuurInfo[0].sig_course_year}}</span>年度专业人员继续教育在线学习，成绩合格，特发此证。具体科目如下：
						</div>
						<div class="printing_course">
							<div class="printing_course_top" :style="{backgroundImage:'url('+ templateImg+')'}">
							  <span>序号</span>
							  <span>年份</span>
							  <span>科目</span>
							  <span class="coursewidth">课程名称</span>
							  <span>学时</span>
							  <span>成绩</span>
							</div>
							<div class="printing_course_Content" :style="{backgroundImage:'url('+ templateImg+')'}" v-for="(item,index) in cuurInfo" :key="index">
								<span>{{(index+1)}}</span>
								<span>{{item.sig_course_year}}</span>
								<span>{{item.dic_name}}</span>
								<span class="coursewidth">{{item.cou_name}}</span>
								<span>{{item.cou_sumtime}}</span>
								<span>合格</span>
							</div>
						</div>
					</div>
					<!--底部-->
					<div class="printing_footer" :style="{backgroundImage:'url('+ templateImg+')'}">
						<div class="printing_footer_info">
							<div class="code" id="qrcode" ref="qrcode"></div>
							<div class="printing_time">
								<p>发证单位（盖章）</p>
								<p>打证时间：{{PublicJs.getCurrentTime()}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="printVisible = false">取 消</el-button>
				<el-button type="primary" v-print="'#print'">打 印</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	export default {
	    data() {
	      return {
				tableData:[],//证书列表
				total:0,
				pageSize:10,
				templateImg:"",
				is_pass:1,
				allOrAdopt:"显示全部",
				isAll:false,
				cuurInfo:[],//当前选中
				printVisible:false,//打印证书弹窗
				multipleSelection:[],//批量打印
			}
		},
		methods:{
			//获取证书列表
			getCertiList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/CertiList", {
					token:window.sessionStorage.getItem('token'),
					page:page,
					limit:this.pageSize,
					is_pass:this.is_pass,
					stex_ismakeup:"",
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						if(response.data.data.template){
							this.templateImg = response.data.data.template.tmp_image;
						}
						this.tableData = response.data.data.data.data;
						this.total = response.data.data.data.total;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//分页
			changPage(val){
				//获取证书列表
				this.getCertiList(val)
			},
			//选项禁用
			checkboxT(row, rowIndex){
				if(row.sig_passtext == 'no'){
					return false;//禁用
				}else{
					return true;//不禁用
				}
			},
			//显示全部
			allOrAdoptFun(){
				this.isAll = !this.isAll;
				if(this.isAll){
					this.allOrAdopt = "显示通过";
					this.is_pass = "";
				}else{
					this.allOrAdopt = "显示全部";
					this.is_pass = 1;
				}
				//获取证书列表
				this.getCertiList(1)
			},
			//多选
			handleSelectionChange(val){
				this.multipleSelection = val;
			},
			//打印
			singlePrint(item){
				var that = this;
				this.cuurInfo = []
				this.cuurInfo.push(item);
				this.printVisible = true;
				this.$nextTick(() => {
					that.$refs.qrcode.innerHTML = "";
					//生成手机端登录二维码
					that.creatQrCode(item.sig_id)
				})
			},
			//批量打印
			batchPrint(){
				var that = this;
				if(this.multipleSelection.length){
					this.cuurInfo = this.multipleSelection;
					this.printVisible = true;
					var multipleSelection =[];
					for(var i=0;i<this.multipleSelection.length;i++){
						multipleSelection.push(this.multipleSelection[i].sig_id)
					}
					this.$nextTick(() => {
						that.$refs.qrcode.innerHTML = "";
						//生成手机端登录二维码
						that.creatQrCode(multipleSelection.join(","))
					})
				}else{
					this.$message({
						message: "请先选择要打印的证书",
						type: 'warning',
						showClose: true
					});
				}
			},
			//生成手机端登录二维码
			creatQrCode(sig_id) {
				var that = this;
				var qrcode = new QRCode(this.$refs.qrcode, {
					text: "https://"+window.location.host+"/CertificateInfo/pcPrintInfo.html?id="+that.PublicJs.Encrypt(sig_id),
					width: 100,
					height: 100,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			},
		},
		created() {
			//获取证书列表
			this.getCertiList(1)
		}
	}
</script>

<style scoped="scoped">
	.batchPrint{
		background: linear-gradient(0deg,#4c6df1 0%, #5e7bf2 100%);
		border-radius: 17px;
		box-shadow: 0px 3px 7px 0px rgba(93,123,241,0.17); 
		width: 76px;
		height: 34px;
		position: absolute;
		text-align: center;
		line-height: 34px;
		right: 20px;
		top: 11px;
		color: #FFF;
		font-size: 14px;
		cursor: pointer;
	}
	.batchPrint:hover{
		background: linear-gradient(0deg, #5e7bf2 100%,#4c6df1 0%);
	}
	.noPrint{
		width: 44px;
		height: 24px;
		background: #999999;
		border-radius: 12px;
		margin: 0 auto;
		color: #FFF;
	}
	.isPrint{
		width: 44px;
		height: 24px;
		background: #5e7bf2;
		border-radius: 12px;
		margin: 0 auto;
		color: #FFF;
		cursor: pointer;
	}
	.courseName{
		text-align: left;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		cursor: pointer;
	}
	.certificatePaginat{
		text-align: center;
		margin-top: 20px;
	}
	.allOrAdopt{
		position: absolute;
		right: 115px;
		top: 0;
		font-size: 14px;
		font-weight: initial;
		cursor: pointer;
		color: #395CE7;
	}
	.prinEdit{
		height: 100%!important;
		padding-bottom: 0px;
	}
	.prinEdit .ant-modal-content{
		height: 100%!important;
	}
	.printingDiv{
		width: 1024px;
		margin: 0 auto;
	}
	.printingTop{
		width: 100%;
		height: 245px;
		background-size: 100%;
		background-repeat: no-repeat;
	}
	
	.printing_student{
		width: 100%;
		padding: 0 153px;
		background-position: 0px -327px;
		background-size: 100%;
		background-repeat: no-repeat;
		font-size: 20px;
		font-weight: 500;
		line-height: 33px;
		color: #333;
	}
	.printing_line{
		border-bottom: 1px solid #000000;
	}
	.printing_course{
		width: 100%;
	}
	.printing_course_top{
		padding: 0 153px;
		background-position: 0px -382px;
		background-size: 100%;
		background-repeat: no-repeat;
		font-size: 0px;
		padding-top: 15px;
	}
	.printing_course_Content{
		padding: 0 153px;
		background-position: 0px -221px;
		background-size: 100%;
		background-repeat: no-repeat;
		font-size: 0px;
		
	}
	.printing_course_top span,.printing_course_Content span{
		display:inline-block;
		background: #f2efe4;
		text-indent: 0px;
		text-align: center;
		font-size: 12px;
		color: #000000;
		line-height: 40px;
		width: 55px;
		margin: 0 2px 2px 0px;
	}
	.printing_course_top span:nth-child(6),.printing_course_Content span:nth-child(6){
		margin-right: 0px;
	}
	.printing_course_top .coursewidth,.printing_course_Content .coursewidth{
		width: 426px;
	}
	.printing_footer{
		width: 100%;
		height: 296px;
		background-position: 0px -428px;
		background-size: 100%;
		background-repeat: no-repeat;
	}
	.printing_footer_info{
		padding: 0 153px;
		padding-top: 30px;
	}
	.printing_footer_info:after{
		display: block;
		clear: both;
		content: "";
	}
	.printing_footer_info .code{
		display: inline-block;
		width: 100px;
		height: 100px;
	}
	.printing_footer_info .printing_time{
		float: right;
		font-size: 19px;
		line-height: 50px;
		color: #000000;
	}
</style>
<style>
	.certificateList .el-table .cell{
		text-align: center;
	}
	@media print {
		.printingDiv{
	        -webkit-print-color-adjust:exact;
	        -moz-print-color-adjust:exact;
	        -ms-print-color-adjust:exact;
	        print-color-adjust:exact;
	    } 
	}
	.printDialog .el-dialog__footer{
		text-align: center;
	}
	.printDialog .el-dialog__header{
		background-color: #5D7BF3;
		height: 54px;
		line-height: 54px;
		padding: 0 20px;
	}
	.printDialog .el-dialog__title,
	.printDialog .el-dialog__headerbtn .el-dialog__close{
		color: #FFF;
	}
	
</style>
<style media="print">
	@page {
		size: auto;  
		margin: 10mm auto;
	}
</style>